import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Virtual homee-in-homee with Node-RED",
  "path": "/Software/Other_Platforms/Homee_and_Node-RED/",
  "dateChanged": "2020-03-13",
  "author": "Mike Polinowski",
  "excerpt": "This time we want to use Node-RED to create a virtual homee that directly connects to the MQTT interface. This virtual device is then controlled by our real homee.",
  "social": "/images/Search/P_SearchThumb_homee.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Other_homee_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "homee"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Virtual homee-in-homee with Node-RED' dateChanged='2020-03-13' author='Mike Polinowski' tag='INSTAR IP Camera' description='This time we want to use Node-RED to create a virtual homee that directly connects to the MQTT interface. This virtual device is then controlled by our real homee.' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/Homee_and_Node-RED/' locationFR='/fr/Software/Other_Platforms/Homee_and_Node-RED/' crumbLabel="homee-in-homee" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "virtual-homee-in-homee-with-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#virtual-homee-in-homee-with-node-red",
        "aria-label": "virtual homee in homee with node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Virtual homee-in-homee with Node-RED`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installing-and-setting-up-node-red-contrib-homee"
        }}>{`Installing and Setting up node-red-contrib-homee`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#homee-in-homee"
        }}>{`homee-in-homee`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connecting-the-mqtt-interface"
        }}>{`Connecting the MQTT Interface`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#toggling-camera-functions"
            }}>{`Toggling Camera Functions`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-a-status-channel"
            }}>{`Adding a Status Channel`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red-flow-export"
        }}>{`Node-RED Flow Export`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`This is part three of our homee documentation. In the first part we looked at the `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/Homee/"
      }}>{`HTTP Interface both homee and your INSTAR camera offers and connected them`}</a>{`. This works with all INSTAR IP cameras. In the second tutorial we used `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/homee/"
      }}>{`Node-RED to connected HTTP interface on the homee side to the MQTT interface on your camera using Node-RED`}</a>{`. `}<strong parentName="p">{`Note`}</strong>{` that the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`INSTAR MQTT Interface`}</a>{` only available in INSTAR Full HD camera models.`}</p>
    <p>{`This time we want to use Node-RED to create a virtual homee that directly connects to the MQTT interface. This virtual device is then controlled by our `}<em parentName="p">{`real`}</em>{` homee.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The complete Node-RED Flow can be `}<a parentName="p" {...{
          "href": "#node-red-flow-export"
        }}>{`downloaded at the end of this article`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installing-and-setting-up-node-red-contrib-homee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installing-and-setting-up-node-red-contrib-homee",
        "aria-label": "installing and setting up node red contrib homee permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing and Setting up node-red-contrib-homee`}</h2>
    <p>{`This sounds a lot more complicated than it is as all we need is already provided by `}<a parentName="p" {...{
        "href": "http://flows.nodered.org/node/node-red-contrib-homee"
      }}>{`node-red-contrib-homee`}</a>{`. Start by installing the homee support from the `}<strong parentName="p">{`Manage Palette`}</strong>{` menu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "810px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/d7542/homee_to_INSTAR_MQTT_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABRElEQVQY033OS0/CQBQF4P5FE6BJEUn8ca6MC2UhUdNqYx8Em7IE7aaGR6dgLzPMm4fpRAgrv9W5i5Nzrat2+7rbvex0HMdpNBrNRq3ZbLb+Ydu247Rs23I9z/f9KIoC43MyGY/HWZaVZYnOFEadyrKYzWZf2Sz/toIwDMMoMdI0BQApJWOMcy6E4EdCCCklZ0wfDsXgw79oT188681/f+i7d73+s+uP0nQ6nUIFAIAKtFgsAKCqzF3BarXSSimtJeds9UMxsbxX//a+f3Pbe3xyR6NRnufz+Xy5XAIAxpicqaoKIQQAlFJMyBpjq346jsMwiuI4SZICIUyIEIJSSgjReqv19sQM1/PYsIIgGBixKSOECCG73Y5zDgDrI8bYfr8/nNlsNn/l2BgOhwCglKKUCiE3NYLXWAihlDqVtdZSSkrpL7/Rjch1GxW0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/e4706/homee_to_INSTAR_MQTT_01.avif 230w", "/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/d1af7/homee_to_INSTAR_MQTT_01.avif 460w", "/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/7fa80/homee_to_INSTAR_MQTT_01.avif 810w"],
              "sizes": "(max-width: 810px) 100vw, 810px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/a0b58/homee_to_INSTAR_MQTT_01.webp 230w", "/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/bc10c/homee_to_INSTAR_MQTT_01.webp 460w", "/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/385bc/homee_to_INSTAR_MQTT_01.webp 810w"],
              "sizes": "(max-width: 810px) 100vw, 810px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/81c8e/homee_to_INSTAR_MQTT_01.png 230w", "/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/08a84/homee_to_INSTAR_MQTT_01.png 460w", "/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/d7542/homee_to_INSTAR_MQTT_01.png 810w"],
              "sizes": "(max-width: 810px) 100vw, 810px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b9607b517d18ed6ad1fa2d0f1d7f2d1c/d7542/homee_to_INSTAR_MQTT_01.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`First drag a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`homeeAPI`}</code>{` node onto your work area and add your `}<strong parentName="p">{`real`}</strong>{` homee's IP address and user login. The node should say that it is connected once you saved your edit:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "884px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/04376e7f25bc11698d4f440aecbce9bf/136a2/homee_to_INSTAR_MQTT_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABZklEQVQoz22RXWvCMBSG+293vftd7mbsdwxvZPiBDBzDspba1DaJMsYYu3KC005tm2pt1b4jhxV0M/CSk0AennNi9Pt9BEGAge9jIAQ8z4MUAlJKCCEwm82wWHwjDEPK53iMyWRC9ypJkCiFeLXC18sr5m/vMCzLhss8MMeB6JlgAw+W48C2bXDOsVwukaoUSilKmqZYr9e0U51liKZTOBeX8K+uYdy3u6i1u+jVG/i4uUW32UDrroZOs0XmcRwhnM8RRTHKssR+v8dut6O9ir5Psw0ipWA0Oo+oNx/wZD5DjIbgloWRaUL6PoSUZKMf6FUUBUWfD4fDv6AsYeiZURiD7/vgUiIYDhFwTnOMoohaI4s0JdO/hsemhuu6qEJAzsGDgNrVn5IkCbIsowfb7RabzYaA2qgCVTUBGWPQOQFyTkBtGMcxmeV5Tu3nRXECO2t4DAx+7XQ0WP8sgfKc5lfZnYsG/gBzUkhp50cYWAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/04376e7f25bc11698d4f440aecbce9bf/e4706/homee_to_INSTAR_MQTT_02.avif 230w", "/en/static/04376e7f25bc11698d4f440aecbce9bf/d1af7/homee_to_INSTAR_MQTT_02.avif 460w", "/en/static/04376e7f25bc11698d4f440aecbce9bf/93034/homee_to_INSTAR_MQTT_02.avif 884w"],
              "sizes": "(max-width: 884px) 100vw, 884px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/04376e7f25bc11698d4f440aecbce9bf/a0b58/homee_to_INSTAR_MQTT_02.webp 230w", "/en/static/04376e7f25bc11698d4f440aecbce9bf/bc10c/homee_to_INSTAR_MQTT_02.webp 460w", "/en/static/04376e7f25bc11698d4f440aecbce9bf/8af20/homee_to_INSTAR_MQTT_02.webp 884w"],
              "sizes": "(max-width: 884px) 100vw, 884px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/04376e7f25bc11698d4f440aecbce9bf/81c8e/homee_to_INSTAR_MQTT_02.png 230w", "/en/static/04376e7f25bc11698d4f440aecbce9bf/08a84/homee_to_INSTAR_MQTT_02.png 460w", "/en/static/04376e7f25bc11698d4f440aecbce9bf/136a2/homee_to_INSTAR_MQTT_02.png 884w"],
              "sizes": "(max-width: 884px) 100vw, 884px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/04376e7f25bc11698d4f440aecbce9bf/136a2/homee_to_INSTAR_MQTT_02.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we are able to create a virtual homee by dragging using a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`homeeDevice`}</code>{` node. Click on `}<strong parentName="p">{`Add new virtualHomee`}</strong>{` to configure it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "738px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/770216442266a2eeeeaa6bcabb5182e0/774b6/homee_to_INSTAR_MQTT_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABGUlEQVQY003Ly07CQAAF0H6tWz/BnSsX/oYLEinER7HSQdJNH1A21gVtMgmUaC3z7EyZzowRTOTk5iZ3cZ0wDOM4OYmi6CPPIYTlEYSwqnaMMXrmb3JOOXfSNF1l2SrLsmwZx8l6vcYYnTRNs98jcoYSgilF2214cZleXTueD+4fJoPRE5iHSZIURUEwrusaYyylFEK0bSvPHJSqq11+NygePWcSgJfpzAfzKE6Xi0VZloeua5qGMWaPjDHWGvvfljIqVd8Z48zfZkEAAAD+69SbBhDCthXHg+21Ub1R2hyU7rU9RWmjrTVKWSEd33t2R2N3OHTdsedNfs+8RQgJIb6w3Hyiqiab3b6qCRGaCI24+pZGvOf85vYHTOBAq1haWHQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/770216442266a2eeeeaa6bcabb5182e0/e4706/homee_to_INSTAR_MQTT_03.avif 230w", "/en/static/770216442266a2eeeeaa6bcabb5182e0/d1af7/homee_to_INSTAR_MQTT_03.avif 460w", "/en/static/770216442266a2eeeeaa6bcabb5182e0/7f4d1/homee_to_INSTAR_MQTT_03.avif 738w"],
              "sizes": "(max-width: 738px) 100vw, 738px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/770216442266a2eeeeaa6bcabb5182e0/a0b58/homee_to_INSTAR_MQTT_03.webp 230w", "/en/static/770216442266a2eeeeaa6bcabb5182e0/bc10c/homee_to_INSTAR_MQTT_03.webp 460w", "/en/static/770216442266a2eeeeaa6bcabb5182e0/65afa/homee_to_INSTAR_MQTT_03.webp 738w"],
              "sizes": "(max-width: 738px) 100vw, 738px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/770216442266a2eeeeaa6bcabb5182e0/81c8e/homee_to_INSTAR_MQTT_03.png 230w", "/en/static/770216442266a2eeeeaa6bcabb5182e0/08a84/homee_to_INSTAR_MQTT_03.png 460w", "/en/static/770216442266a2eeeeaa6bcabb5182e0/774b6/homee_to_INSTAR_MQTT_03.png 738w"],
              "sizes": "(max-width: 738px) 100vw, 738px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/770216442266a2eeeeaa6bcabb5182e0/774b6/homee_to_INSTAR_MQTT_03.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Specify a unique homee ID. You can use letters and numbers (max. 10 characters) and add a user login for the virtual device:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "743px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/98a58e56e0a4aba1283b5fdce1a633f1/f2793/homee_to_INSTAR_MQTT_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.260869565217384%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABOElEQVQY023R30rDMBQG8L6i976Bt76TeCOCm3OjG3NO27UxdV5LZ7Sxo9Jgk/RPkiaVtAjC9rs4dx/n4xzH930IYdQDAMRxTAjJsiz7zvI8T9MUIbTbvSOEkiShlHLOyVdKPpOfnDjw2UaHOAAAIcQ552XJGCuKglJGLTs5s5Px0js7vz85/ViunfFsfnk9vrgaLVePEEKEECEEY1wUhVKqaRohhPqnbdv8LY43IdnvnbXnPXn+Jgij6AUAkCSJ0bqqKqVU1zMHtDHS6LppnIXrjsaTm9Ht5G4abHyMsWiaqqq01mVZcsb0obbtTCeEcNyFO5tOF/P5w2oVBgHGmHFGGZNKlb1j2dYYY8Pb7fb1z1Dbbq5t7bqupZS254Gu6zdH/amHbwEAbG0hKKVSSiGElFIfM4R/AVwAs190/D9jAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98a58e56e0a4aba1283b5fdce1a633f1/e4706/homee_to_INSTAR_MQTT_04.avif 230w", "/en/static/98a58e56e0a4aba1283b5fdce1a633f1/d1af7/homee_to_INSTAR_MQTT_04.avif 460w", "/en/static/98a58e56e0a4aba1283b5fdce1a633f1/96163/homee_to_INSTAR_MQTT_04.avif 743w"],
              "sizes": "(max-width: 743px) 100vw, 743px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/98a58e56e0a4aba1283b5fdce1a633f1/a0b58/homee_to_INSTAR_MQTT_04.webp 230w", "/en/static/98a58e56e0a4aba1283b5fdce1a633f1/bc10c/homee_to_INSTAR_MQTT_04.webp 460w", "/en/static/98a58e56e0a4aba1283b5fdce1a633f1/53666/homee_to_INSTAR_MQTT_04.webp 743w"],
              "sizes": "(max-width: 743px) 100vw, 743px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98a58e56e0a4aba1283b5fdce1a633f1/81c8e/homee_to_INSTAR_MQTT_04.png 230w", "/en/static/98a58e56e0a4aba1283b5fdce1a633f1/08a84/homee_to_INSTAR_MQTT_04.png 460w", "/en/static/98a58e56e0a4aba1283b5fdce1a633f1/f2793/homee_to_INSTAR_MQTT_04.png 743w"],
              "sizes": "(max-width: 743px) 100vw, 743px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/98a58e56e0a4aba1283b5fdce1a633f1/f2793/homee_to_INSTAR_MQTT_04.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now I want to add a device that allows me to switch the alarm areas on my camera `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`on`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`off`}</code>{`. Give it a unique `}<strong parentName="p">{`Node-ID`}</strong>{` and choose the `}<strong parentName="p">{`OnOffPlug`}</strong>{` profile. Then continue by clicking on `}<strong parentName="p">{`Attributes`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "746px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7194b886ae5b640749e0ef1546b0c612/62de4/homee_to_INSTAR_MQTT_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABlklEQVQoz52Qy3LTMBSG/YjNS7DmKVgyLHiKsuIyqUMaDzgp7cRx5FilyYoNdS4Tp45kW5ejixnbQJNAWfBtdEZzPp1fxzn7Pzqds07H8X3f87zhcDj6BY7xfD7HGN/dfV0sFvf3yfKA1WpVH8kySRIHYxzHs9uGpo53aSqE4JwXDfyA+haA7XbjZ8/xi5dOEEw+9Abnby/evHO/3IwjhNbr9Wa9SZJlWZYAIISQUkJDXSiVbjbfvM/fxxNnEob+1Wg0urq5vo4QCsMwyzJjjJTSWls12AOqquKcK2PAGCecBJ+8gev2ut2LweUlQmi73QJA26qU0lrbY0yDtdYJgrHb77kf+/2B5/v+dDpN05QSQikFkNnDw35P9DGc8zaXgxCKomgWRXHNrJXLsmyfL4oiz3MAMH/wUz6klTmrd9s2VVV1ommt/yUTQoQQ1lpKaZ7nWh3FVkq1S/m7LIVsd8NK9jvCyfAn5SIvlFLGGEqplLLtfpystSgKw/ipHIbhfp8BQFGWjDFCyOmflVJVlb3vslevfwDy0Z7kR6hVCQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7194b886ae5b640749e0ef1546b0c612/e4706/homee_to_INSTAR_MQTT_05.avif 230w", "/en/static/7194b886ae5b640749e0ef1546b0c612/d1af7/homee_to_INSTAR_MQTT_05.avif 460w", "/en/static/7194b886ae5b640749e0ef1546b0c612/2effe/homee_to_INSTAR_MQTT_05.avif 746w"],
              "sizes": "(max-width: 746px) 100vw, 746px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7194b886ae5b640749e0ef1546b0c612/a0b58/homee_to_INSTAR_MQTT_05.webp 230w", "/en/static/7194b886ae5b640749e0ef1546b0c612/bc10c/homee_to_INSTAR_MQTT_05.webp 460w", "/en/static/7194b886ae5b640749e0ef1546b0c612/f7ebd/homee_to_INSTAR_MQTT_05.webp 746w"],
              "sizes": "(max-width: 746px) 100vw, 746px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7194b886ae5b640749e0ef1546b0c612/81c8e/homee_to_INSTAR_MQTT_05.png 230w", "/en/static/7194b886ae5b640749e0ef1546b0c612/08a84/homee_to_INSTAR_MQTT_05.png 460w", "/en/static/7194b886ae5b640749e0ef1546b0c612/62de4/homee_to_INSTAR_MQTT_05.png 746w"],
              "sizes": "(max-width: 746px) 100vw, 746px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7194b886ae5b640749e0ef1546b0c612/62de4/homee_to_INSTAR_MQTT_05.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Inside the JSON editor add the following object:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"node_id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"instance"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"minimum"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"maximum"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"current_value"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"target_value"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"last_value"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"unit"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"step_value"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"editable"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"state"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"last_changed"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12345555`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"changed_by"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"changed_by_id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"based_on"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"data"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`The attribute `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`id`}</code>{` has to be unique and the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node_id`}</code>{` is the ID that you choose for your switch in the previous step. And the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`type`}</code>{` value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` defines your switch as a switch -> `}<a parentName="p" {...{
        "href": "https://github.com/stfnhmplr/homee-api/blob/master/lib/enums.js"
      }}>{`see CAAttributeType`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b4c068829a3ab4d58fc000795760c0f/6bbf7/homee_to_INSTAR_MQTT_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABgElEQVQoz5XS0W6bMBgFYF5w932sXu1hKqUNhGTZlrYB/z80DlrvIjCGYmpDbLBaUapN0aIk/a6QpaNzLOPkec5YzhjLeV4UxfOfAedcSvl6zHheC1EL4Yhj6lrURwnxqnUakMm3q/D6u7Pf740x+tD+kNbajLTurG3Kl9SdF/HG0VoXRcEY6/vu7UPbtmmaZlkmhBgW1rUx5u0/1lpnrDXG9H1vre2t7bpu7DfGdB/+fowapV7KslHKkVLudrskSTabDaV0S5+251BK4zjmnA93FlVFt1sSBDCfww8PgkdEwJMIIYwxp23bqqqSJEEAuL2F6RSDFUZnAMBBGAiBuym4Lvxc4vohivCiMOf8M+y6MPNg7mNwf2lznuefYc8jvk8eV4jwtTAOs+9g4SMJIsQvhgHQ88CfwXIZh/dP62UEJIriU2Gl1L/Zkwnc3MBiAetVhOREMyKmaTo0Z1lGKSVhiLMZ+f0LSDisOPfOWZYNPwnnvCxLOVJKSiUv0DTNO2HvUCRV1c6VAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b4c068829a3ab4d58fc000795760c0f/e4706/homee_to_INSTAR_MQTT_06.avif 230w", "/en/static/9b4c068829a3ab4d58fc000795760c0f/d1af7/homee_to_INSTAR_MQTT_06.avif 460w", "/en/static/9b4c068829a3ab4d58fc000795760c0f/04ac8/homee_to_INSTAR_MQTT_06.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b4c068829a3ab4d58fc000795760c0f/a0b58/homee_to_INSTAR_MQTT_06.webp 230w", "/en/static/9b4c068829a3ab4d58fc000795760c0f/bc10c/homee_to_INSTAR_MQTT_06.webp 460w", "/en/static/9b4c068829a3ab4d58fc000795760c0f/d8378/homee_to_INSTAR_MQTT_06.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b4c068829a3ab4d58fc000795760c0f/81c8e/homee_to_INSTAR_MQTT_06.png 230w", "/en/static/9b4c068829a3ab4d58fc000795760c0f/08a84/homee_to_INSTAR_MQTT_06.png 460w", "/en/static/9b4c068829a3ab4d58fc000795760c0f/6bbf7/homee_to_INSTAR_MQTT_06.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b4c068829a3ab4d58fc000795760c0f/6bbf7/homee_to_INSTAR_MQTT_06.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "homee-in-homee",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#homee-in-homee",
        "aria-label": "homee in homee permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`homee-in-homee`}</h2>
    <p>{`We are now ready to add our virtual homee to the real one on our network. For this open the device menu and click to add a new `}<strong parentName="p">{`WiFi`}</strong>{` device:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/61e6f872cfdbdda6f71bdeec27780137/e5715/homee_to_INSTAR_MQTT_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADdUlEQVQoz3XN7W8adQDA8fsr+sY3LQ/BchwcD0cB23UUSincesDdcT9uhTueCofVurqzqSajHu2c7bKlNnVzzk23OdqlTUfhjg7JiKYuRt0Sk/mQqYtbbDJjfOELk8UswRTdCxP9vP/mC3FcAgCQzmQmJydZlk0mkwCA1dXV3d3dVqvVbDZlWVb+TZblRqNxvCRB0QhL0zTHcclk8nAHx3GlUkmSpPn5eUmSisXisY5isTjXUSwWSyXpankd2mitTGTzHM+xLMvzPMMwhYKQSCS6uro0Go1apdJqtXq9HoZhnU6nUqk0Gk1PT09vb299pw6dXVugqShBEESICBGEz+fL5/OpVEqn09lsNhg1YRjm2TfUP9CPmk0Wqxk1ozbMVtmuQYXciwRBUBRFkiRF0YFAIJfLpVMprVZrQdF3YJPHgDxrREyICYFR1GgxIWajwWy32ze2KtCDBz9xHEfTdKenAoFAXhB4jtPq9eTAwK/PqKcxO9LnsFgMLg/qdJtcQ2jfIGKxodWqDCXTWb/fHwwGR0dH/X6/2+0WBCEGwEtHjlQrlaa0IK+vSa+/Gck63/+ePvcVef4udeZ2eDjkqG0r0ObFs6lMdiKbiUQigUDA4/EIggAAmJmZaTQaa5WKcmNnceEUXXBd+wVcuh/98CFz8R45EnFUKwp0a3stdnj8EI4Hg0Ecx71eb6FQAACIoliv16+Vy7JcfaN0ksq7yj8zF76LfPADdf5r0hfuxGYbZrVaMQyz2Wx2ux1BENAxOzt7s9Wq1mrNmx8tnVhmJl2bv8U29vjNR9yl+9QI6aheVyC9vtdgMPT1OZxOp9Fo7O7uxnE8Ho97DrozyRQfT6R5PoSHXF4UiP3s0SFWdJNTTscBqyLXIRiGEQQxPaVSqcYIIhFjnc/zdHmVOLdEX3lrcE6MH8Q+Efw7WW8z51PSw8HnsG1Z2Y9hGNZ3GAwGtVo9FiLG6Si+UhIf3Xnh25a490V4471XCFf7DHiyHG2vMI9PkcDj2Ko+jf/2T0wQ41Fm9OSxqR8/nbhdn7r38aErb0/jzvbp6B8nIn8uUb8fD0eHHNdr/xnvn5ngsvTy3pdT37SOPvyMWH9XHHO1V5gnp2PtZfB4MbJ/rv3POQ5ig2IhfeNqdutCWrk8vDiXHrbfnQ1+/hp559XwremRsQP2iqz8BUAPgDs5HndRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/61e6f872cfdbdda6f71bdeec27780137/e4706/homee_to_INSTAR_MQTT_07.avif 230w", "/en/static/61e6f872cfdbdda6f71bdeec27780137/d1af7/homee_to_INSTAR_MQTT_07.avif 460w", "/en/static/61e6f872cfdbdda6f71bdeec27780137/0bbb0/homee_to_INSTAR_MQTT_07.avif 768w"],
              "sizes": "(max-width: 768px) 100vw, 768px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/61e6f872cfdbdda6f71bdeec27780137/a0b58/homee_to_INSTAR_MQTT_07.webp 230w", "/en/static/61e6f872cfdbdda6f71bdeec27780137/bc10c/homee_to_INSTAR_MQTT_07.webp 460w", "/en/static/61e6f872cfdbdda6f71bdeec27780137/8b983/homee_to_INSTAR_MQTT_07.webp 768w"],
              "sizes": "(max-width: 768px) 100vw, 768px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/61e6f872cfdbdda6f71bdeec27780137/81c8e/homee_to_INSTAR_MQTT_07.png 230w", "/en/static/61e6f872cfdbdda6f71bdeec27780137/08a84/homee_to_INSTAR_MQTT_07.png 460w", "/en/static/61e6f872cfdbdda6f71bdeec27780137/e5715/homee_to_INSTAR_MQTT_07.png 768w"],
              "sizes": "(max-width: 768px) 100vw, 768px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/61e6f872cfdbdda6f71bdeec27780137/e5715/homee_to_INSTAR_MQTT_07.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose a `}<strong parentName="p">{`homee in homee`}</strong>{` device and select `}<strong parentName="p">{`Add new homee`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "766px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/83b54240a36f6be3a8398ce3e3a8ff48/f7616/homee_to_INSTAR_MQTT_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACiElEQVQoz23ST0/aYBzA8b4PExMTsEhL4eFP+5RS2seitLRV2gIiIPbB8gAKysGjRz2buMUsMdltyZbstuy88y6CvoDdNt/GQqvJDvukhydtvv0lvzzUsd9vt9tBEMzOzzudDsa41+tdXl7e3d3d39/f3t5eX1/f/M/V1RXVcjuNZrPf7/u+3+12o34+n5+cnEwmE0KIHzoODUIY4yAIHh4eqK8/3o/IxMer0vf9drs9Ho8xxmtra4lEYnNzk2GYdIjjOJqmE4lEPB7nOO75+Zn68Pmm1Tqov6lW9dFohDGOx+O5XC6dTkMIEUKqqiqKkslkAACZTCafzz8+PlITMtvf3/M8z3XdRqNRMwxCyGAwoGma5/lcLgcA4HlekqR0Op3NZvP5fDabLRQKq/jl5TceDJqhVVyrRZPX19cZhtkKJZNJhmGSyWR0pmkaALBYLCgcDA3DME3TMAxd1xFCw+GQEAIAkGUZQsi/EULRG0mSVpO/f/oYEDIcBp7n1oxapVIhhIzHYwAAQqhUKkmSBP8hiqIgCLIsL5dL6ue3L53ekWWalmXZth3F0+lUEATHcSzLchxH0zQIYTEU/Qsh9PT0RLGr/YH8m1Qq5brubDYTRdG27Xq9jhASBEEUxf/EW1sJlmWjzxzHbWxsGIZxNp0WBKEaLgNpmiTLkizDYhEWi6Ik8RAqqhpOZtlUKsVxXHQTYrGYaZoXZ2dyoeCZVtO2e16j32p1XW+7WFSggERR4fldRVnFTCgZYlk2FovphnEzObWh2K3qhzs7hzu7brnsyK+PW1b2SvLRtvZruXyNI1Fc1fV349OjsjJvtmaOe+E1+ttap6x0FbWrqD0VHZSVqVb5s1j8BTBSBgao0VYAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/83b54240a36f6be3a8398ce3e3a8ff48/e4706/homee_to_INSTAR_MQTT_08.avif 230w", "/en/static/83b54240a36f6be3a8398ce3e3a8ff48/d1af7/homee_to_INSTAR_MQTT_08.avif 460w", "/en/static/83b54240a36f6be3a8398ce3e3a8ff48/27214/homee_to_INSTAR_MQTT_08.avif 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/83b54240a36f6be3a8398ce3e3a8ff48/a0b58/homee_to_INSTAR_MQTT_08.webp 230w", "/en/static/83b54240a36f6be3a8398ce3e3a8ff48/bc10c/homee_to_INSTAR_MQTT_08.webp 460w", "/en/static/83b54240a36f6be3a8398ce3e3a8ff48/3fcdd/homee_to_INSTAR_MQTT_08.webp 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/83b54240a36f6be3a8398ce3e3a8ff48/81c8e/homee_to_INSTAR_MQTT_08.png 230w", "/en/static/83b54240a36f6be3a8398ce3e3a8ff48/08a84/homee_to_INSTAR_MQTT_08.png 460w", "/en/static/83b54240a36f6be3a8398ce3e3a8ff48/f7616/homee_to_INSTAR_MQTT_08.png 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/83b54240a36f6be3a8398ce3e3a8ff48/f7616/homee_to_INSTAR_MQTT_08.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in the homee ID and login you have assigned to your virtual homee in Node-RED:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "766px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2e04065b22e91725e820460f54ce54af/f7616/homee_to_INSTAR_MQTT_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB3UlEQVQoz3XQy27aQBQGYB6g1OMZM/bYY1NswGOkuOoYzGWEDbiC4IiUVAXhJQmUqA9Rqas+Y3ZJu+hFqrrqZVnVKFLS0G850n/+M6fQj6IgCCil5fITK0cp5ZwTQjSMNVXFGCv3lUolAEC32y0wxmq1qm3bjuOYpmlZlmmazzgPKhVRsUXFblCKVVXTNPWWpmkIoV6vV8hepNPnQ8ep1arVck7Xdc65RallGGVKqa6XcgfCzK07jk2ITnKWZTHGWq2W53mu67qMua5br9cdx8EY76dgjGVZ/ru2lEMIYYwRQoSQJElms1mapqenp/P5PE3T0SiJ4xhCKOcghMVisd1uF/ZPCCFFKUEIdUMPw1AI0e10ff/oqe/7/pHv+81mEyEEALgXlm8BACRJIoQMBsPFcnX28tV4Mp0cp5PjdDo96XQ6CD1olu+AEErS47fv3l/dfL+6/vbhy6/rTz9uPv/8+PX3YpkVi4/2ax4OAwBUVU1PZtvdm4vt5flmt97s1he7881lvx/BOx0HwpIkGYYRBEGzGQQBd+s1xlzPY42GxzlXFOW/f943a5o2Ho9Xq2yxWGy3r9fr9XK5zLJMCHHg2v+ECSFCiOFwGMdxkiSj0SiKosFgEIbhw2v/AW74dYkOnlpSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2e04065b22e91725e820460f54ce54af/e4706/homee_to_INSTAR_MQTT_09.avif 230w", "/en/static/2e04065b22e91725e820460f54ce54af/d1af7/homee_to_INSTAR_MQTT_09.avif 460w", "/en/static/2e04065b22e91725e820460f54ce54af/27214/homee_to_INSTAR_MQTT_09.avif 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2e04065b22e91725e820460f54ce54af/a0b58/homee_to_INSTAR_MQTT_09.webp 230w", "/en/static/2e04065b22e91725e820460f54ce54af/bc10c/homee_to_INSTAR_MQTT_09.webp 460w", "/en/static/2e04065b22e91725e820460f54ce54af/3fcdd/homee_to_INSTAR_MQTT_09.webp 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2e04065b22e91725e820460f54ce54af/81c8e/homee_to_INSTAR_MQTT_09.png 230w", "/en/static/2e04065b22e91725e820460f54ce54af/08a84/homee_to_INSTAR_MQTT_09.png 460w", "/en/static/2e04065b22e91725e820460f54ce54af/f7616/homee_to_INSTAR_MQTT_09.png 766w"],
              "sizes": "(max-width: 766px) 100vw, 766px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2e04065b22e91725e820460f54ce54af/f7616/homee_to_INSTAR_MQTT_09.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your virtual homee should now show up. Click on `}<strong parentName="p">{`Send`}</strong>{` to add it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/682f19a27f256828c3284f1c06e3ca85/0f67e/homee_to_INSTAR_MQTT_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABKElEQVQoz3XQ0WqDMBQGYB/AaVScpa1UWBVJrAk0UVJ0qYXWQW9me1kKfRBbb0rxscciK2y67+4Efv6To5TFO0/Z23zued5kMnFddzweh2Ho+/50Op3NZqPRyLZtZ4hCCYZhOPf9KIqQBCEkhGCMEUJRFEEIgyBwHOe1R9F08KJpi8Viv9+XZfkhbbfbUtrtduv1Ossy0zSNHsU0TQAAQihJEoxxHMf4N0IIpdSyLADA37BhGF1YCFEUxWazWa1W/AeltKqqy+Wiqmq//LtZ0zRCSF3X9/v98Xjcbrdaaprmer22bXs+n4fDz+Ysy/I8737Y1QqJc75cLv9dW9f1OI4Ph8OnVFXV6XR6jsfjUQgxfLCuOQiCNE0TiTGW5znnnDHWvWCMB8NfBpZaPxuQ/JAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/682f19a27f256828c3284f1c06e3ca85/e4706/homee_to_INSTAR_MQTT_10.avif 230w", "/en/static/682f19a27f256828c3284f1c06e3ca85/d1af7/homee_to_INSTAR_MQTT_10.avif 460w", "/en/static/682f19a27f256828c3284f1c06e3ca85/7f308/homee_to_INSTAR_MQTT_10.avif 920w", "/en/static/682f19a27f256828c3284f1c06e3ca85/10701/homee_to_INSTAR_MQTT_10.avif 921w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/682f19a27f256828c3284f1c06e3ca85/a0b58/homee_to_INSTAR_MQTT_10.webp 230w", "/en/static/682f19a27f256828c3284f1c06e3ca85/bc10c/homee_to_INSTAR_MQTT_10.webp 460w", "/en/static/682f19a27f256828c3284f1c06e3ca85/966d8/homee_to_INSTAR_MQTT_10.webp 920w", "/en/static/682f19a27f256828c3284f1c06e3ca85/cd6b8/homee_to_INSTAR_MQTT_10.webp 921w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/682f19a27f256828c3284f1c06e3ca85/81c8e/homee_to_INSTAR_MQTT_10.png 230w", "/en/static/682f19a27f256828c3284f1c06e3ca85/08a84/homee_to_INSTAR_MQTT_10.png 460w", "/en/static/682f19a27f256828c3284f1c06e3ca85/c0255/homee_to_INSTAR_MQTT_10.png 920w", "/en/static/682f19a27f256828c3284f1c06e3ca85/0f67e/homee_to_INSTAR_MQTT_10.png 921w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/682f19a27f256828c3284f1c06e3ca85/c0255/homee_to_INSTAR_MQTT_10.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now try your switch by toggling it in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`On`}</code>{` position:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "919px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ece27368cf64bbcf2d9072bff5d3b40d/6295b/homee_to_INSTAR_MQTT_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABsElEQVQoz02RyY7aQBCG/T4It+3eqtsYjLHxwjpglgCekaIklmg04c5pAjmBAlLyACRPGmHDaL5Ll1QqfV31a9PJbDabPb8853k+n39arVZZlimlTqfT5XI5Ho+Hw2H/kR/393w+a5+/vkynt/nlcjmfzyfpZDweK6VGo9FwOOz1ekmSxHHsB0EcRWGn43U6ScFgMNB+/3vzW1EcR0HgB+2253ntdnu9XpumaT1gjD0Nn4TjBDrKKtWyYRiG1u/3pRD1Aqfu2DXb932lNkII+0EYht9fX8N+/4XDHlnvDe3v9SoAPM9rNBqu6zpOvTQTQmzbBgDOOaWUcy4ALM6rAko459pkOnNdt9lsAoAQgjHm+36e55VKpVarYYwRQoZhAADG2ECGiRClFGOs67p2/fMr7vaiMASAUtVqtZRSN5UQ9AEpoJRgQhnnskBbf/sibbtYuO44TrnhZrMpP4YQwhhblkUIuRWY2Fw3DV1K+za8P/xcLBZZlpUBRFGUpul2uwUAKWVh+2imnBHG6P1gu92u2+2WeSZJEoZhmqZKqWq1+h4VxlhKyRgzzXuCRWH+B1eLp9uDC8k3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ece27368cf64bbcf2d9072bff5d3b40d/e4706/homee_to_INSTAR_MQTT_11.avif 230w", "/en/static/ece27368cf64bbcf2d9072bff5d3b40d/d1af7/homee_to_INSTAR_MQTT_11.avif 460w", "/en/static/ece27368cf64bbcf2d9072bff5d3b40d/6fa54/homee_to_INSTAR_MQTT_11.avif 919w"],
              "sizes": "(max-width: 919px) 100vw, 919px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ece27368cf64bbcf2d9072bff5d3b40d/a0b58/homee_to_INSTAR_MQTT_11.webp 230w", "/en/static/ece27368cf64bbcf2d9072bff5d3b40d/bc10c/homee_to_INSTAR_MQTT_11.webp 460w", "/en/static/ece27368cf64bbcf2d9072bff5d3b40d/5041b/homee_to_INSTAR_MQTT_11.webp 919w"],
              "sizes": "(max-width: 919px) 100vw, 919px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ece27368cf64bbcf2d9072bff5d3b40d/81c8e/homee_to_INSTAR_MQTT_11.png 230w", "/en/static/ece27368cf64bbcf2d9072bff5d3b40d/08a84/homee_to_INSTAR_MQTT_11.png 460w", "/en/static/ece27368cf64bbcf2d9072bff5d3b40d/6295b/homee_to_INSTAR_MQTT_11.png 919w"],
              "sizes": "(max-width: 919px) 100vw, 919px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ece27368cf64bbcf2d9072bff5d3b40d/6295b/homee_to_INSTAR_MQTT_11.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adding a debug node to our virtual homee shows us that we now receive a status update about our switch whenever it is toggled:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"attributeId"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"targetValue"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"attributeId"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"targetValue"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "827px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4dc861557e6c574df314172e58bad593/4e6ec/homee_to_INSTAR_MQTT_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA4ElEQVQY003QzU6DQBQFYN7/YYwLX8AYG9pFE42lhgimtjhAywWGOzA/d+aawU2/1Vmdk5zEOaf1wqsQAhH5O9ZaROz7HgDkNBGRMQYAmrZVSiVEdAVZ/NRncaPI/VszMbNCJYRAxDGSwzBAB6OMEmPtPi/StzwrqsCB73jvmXlC/K0qCYDDYLS21hljvPda68SRE1f4vjTlpW272/Fr955tDp8b0Zy8j11SyrqucZxlh2qmtTBaliUu77L8dX/cfpTnqkr3T8/p48v2oTwdfIgvKKUAgKzXsyHyYcXMxpg/Jmga27+O+7sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4dc861557e6c574df314172e58bad593/e4706/homee_to_INSTAR_MQTT_12.avif 230w", "/en/static/4dc861557e6c574df314172e58bad593/d1af7/homee_to_INSTAR_MQTT_12.avif 460w", "/en/static/4dc861557e6c574df314172e58bad593/d429d/homee_to_INSTAR_MQTT_12.avif 827w"],
              "sizes": "(max-width: 827px) 100vw, 827px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4dc861557e6c574df314172e58bad593/a0b58/homee_to_INSTAR_MQTT_12.webp 230w", "/en/static/4dc861557e6c574df314172e58bad593/bc10c/homee_to_INSTAR_MQTT_12.webp 460w", "/en/static/4dc861557e6c574df314172e58bad593/97d4f/homee_to_INSTAR_MQTT_12.webp 827w"],
              "sizes": "(max-width: 827px) 100vw, 827px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4dc861557e6c574df314172e58bad593/81c8e/homee_to_INSTAR_MQTT_12.png 230w", "/en/static/4dc861557e6c574df314172e58bad593/08a84/homee_to_INSTAR_MQTT_12.png 460w", "/en/static/4dc861557e6c574df314172e58bad593/4e6ec/homee_to_INSTAR_MQTT_12.png 827w"],
              "sizes": "(max-width: 827px) 100vw, 827px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4dc861557e6c574df314172e58bad593/4e6ec/homee_to_INSTAR_MQTT_12.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "connecting-the-mqtt-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connecting-the-mqtt-interface",
        "aria-label": "connecting the mqtt interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting the MQTT Interface`}</h2>
    <h3 {...{
      "id": "toggling-camera-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#toggling-camera-functions",
        "aria-label": "toggling camera functions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Toggling Camera Functions`}</h3>
    <p>{`We now have to change this message into something useful for us. For example, we can use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`targetValue`}</code>{` to switch the 4 alarm areas on our camera with the MQTT topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/local/alarm/area1/enable/raw`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/local/alarm/area4/enable/raw`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "884px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b0d61d13e692c2475684a69c8b8be20/136a2/homee_to_INSTAR_MQTT_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABF0lEQVQY03XQ3U6DMByH4d7/RZjoEs81izqFgIwxILS0zOwClnAyPtYCTT+E4gYuUROf/I+avAf9AQghgjAjhGCMJukkz/OiLIrjkVLa/MAYa7vu4K4PlgMghLa7Xq5sx9tMFZqvKItTXTPKhBCcc3ElpRRK7m/v9zcLkEBov3uOuw6jGF/sMNmlOKOUXhopx3E0v2mthVbaDAAhFEaxH2z9IIqjTRSsQv8lid2qqpRSozG86zrO//RmGEZjQJLA5av1+PT2bHlb3wqchW/fxZuHsig57/q+H/4H5nkIwZfFMMZkh8kHxlnTNEqpz0lVVXVdSyH6YegnWmsp5XeM0HTXtdIUNQ2bPzvHlJ7atmWMaa3nx3P8BSn1dXedKl6eAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b0d61d13e692c2475684a69c8b8be20/e4706/homee_to_INSTAR_MQTT_13.avif 230w", "/en/static/4b0d61d13e692c2475684a69c8b8be20/d1af7/homee_to_INSTAR_MQTT_13.avif 460w", "/en/static/4b0d61d13e692c2475684a69c8b8be20/93034/homee_to_INSTAR_MQTT_13.avif 884w"],
              "sizes": "(max-width: 884px) 100vw, 884px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b0d61d13e692c2475684a69c8b8be20/a0b58/homee_to_INSTAR_MQTT_13.webp 230w", "/en/static/4b0d61d13e692c2475684a69c8b8be20/bc10c/homee_to_INSTAR_MQTT_13.webp 460w", "/en/static/4b0d61d13e692c2475684a69c8b8be20/8af20/homee_to_INSTAR_MQTT_13.webp 884w"],
              "sizes": "(max-width: 884px) 100vw, 884px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b0d61d13e692c2475684a69c8b8be20/81c8e/homee_to_INSTAR_MQTT_13.png 230w", "/en/static/4b0d61d13e692c2475684a69c8b8be20/08a84/homee_to_INSTAR_MQTT_13.png 460w", "/en/static/4b0d61d13e692c2475684a69c8b8be20/136a2/homee_to_INSTAR_MQTT_13.png 884w"],
              "sizes": "(max-width: 884px) 100vw, 884px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b0d61d13e692c2475684a69c8b8be20/136a2/homee_to_INSTAR_MQTT_13.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The message we are looking for arrives with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`msg.payload.targetValue`}</code>{`. By setting it to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`msg.payload`}</code>{` we can directly feed it into 4 MQTT-Out Nodes to switch our 4 areas on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` or off `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7dea5a7c6171b4f38d15c35a95486979/8ae78/homee_to_INSTAR_MQTT_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA8klEQVQY003LwXKCMBCAYd7/VTqOdkYbwbYUCwkmwIZriw59A2SwbLIbpqOnfqf/8kcnbQ7pMc0KgAaapmnqe4AFa6u67vt+GIbr9fr7jwvhvIu7zTY6Gf1xzKUqAWxzf1qjdSaSTBzSnfi5XGZE59yyLMy8PDDz7BDJR+qk49c0eTsWxXuePRX5Os8zsX4Rq51YbbuvzhMx8zAM4zjiAxExEXuKtKkKpVSpjdFGf1aVlFKK5zjeJPv1vvs+e++Zg3MOEW+3aZom730IgYgiAGittRYeWoC2qmtZyFKWSqq+7xGRmRFxnmfnnPeemEMIzPwH7b0C7P+ruQ4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7dea5a7c6171b4f38d15c35a95486979/e4706/homee_to_INSTAR_MQTT_14.avif 230w", "/en/static/7dea5a7c6171b4f38d15c35a95486979/d1af7/homee_to_INSTAR_MQTT_14.avif 460w", "/en/static/7dea5a7c6171b4f38d15c35a95486979/7f308/homee_to_INSTAR_MQTT_14.avif 920w", "/en/static/7dea5a7c6171b4f38d15c35a95486979/8ad02/homee_to_INSTAR_MQTT_14.avif 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7dea5a7c6171b4f38d15c35a95486979/a0b58/homee_to_INSTAR_MQTT_14.webp 230w", "/en/static/7dea5a7c6171b4f38d15c35a95486979/bc10c/homee_to_INSTAR_MQTT_14.webp 460w", "/en/static/7dea5a7c6171b4f38d15c35a95486979/966d8/homee_to_INSTAR_MQTT_14.webp 920w", "/en/static/7dea5a7c6171b4f38d15c35a95486979/7f3f2/homee_to_INSTAR_MQTT_14.webp 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7dea5a7c6171b4f38d15c35a95486979/81c8e/homee_to_INSTAR_MQTT_14.png 230w", "/en/static/7dea5a7c6171b4f38d15c35a95486979/08a84/homee_to_INSTAR_MQTT_14.png 460w", "/en/static/7dea5a7c6171b4f38d15c35a95486979/c0255/homee_to_INSTAR_MQTT_14.png 920w", "/en/static/7dea5a7c6171b4f38d15c35a95486979/8ae78/homee_to_INSTAR_MQTT_14.png 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7dea5a7c6171b4f38d15c35a95486979/c0255/homee_to_INSTAR_MQTT_14.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Don't forget that you have to `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
      }}>{`add your camera's MQTT Broker`}</a>{` to the MQTT node.`}</p>
    <p>{`Using a tool like `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT.fx`}</a>{` now shows us that those MQTT Topics get updated every time the switch is used. Every message with payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` will switch all 4 areas on, while a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` deactivates them:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/537e631b24636824f7b038bc6f7f6aeb/homee_to_INSTAR_MQTT_15.gif",
        "alt": "Homee to INSTAR MQTT Broker"
      }}></img></p>
    <h3 {...{
      "id": "adding-a-status-channel",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-a-status-channel",
        "aria-label": "adding a status channel permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding a Status Channel`}</h3>
    <p>{`The sequence above already works great. But there is one problem with it. If we use our camera's WebUI or a smartphone app to switch the alarm areas on or off we are not receiving an update in homee. For this we have to add our camera's MQTT `}<strong parentName="p">{`Status Channel`}</strong>{` as an input to our virtual homee:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "808px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/61ac87aaa1095ccdd84968ed2b752235/3534c/homee_to_INSTAR_MQTT_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABCUlEQVQY01XGwW6CMACAYd7/GbbTjjsMPWyZzB0WKWSjFXCbWkUQqqGA02ItIqUsy5Il+/Iffm2f7emaplH60zr9HX4ULJ8WwX2ZTysa8djjic+JX2e4+GIfq224zaWU2sLBdt8GPWDqpqmDkT6y+1aIo01or9BVjA2KzWw2Sj+tjf9SYMeb42cwHr8vS8Y0jPDbgwMHEA4QGiD46LjGmJL8wLZxeLuYXpP1Dc2T2WuEhhPH8KAB3SfoDd0sybRdyYpidyy54EJwcTqeBBdNI1XbSlm3bd11kh0OroEsHVg9YPUscAfcoVuJSlNKdf+pTrWq/aOUOl9qPA9Wk4DghCwJWRAaZ82l+QYdpgcNzYCRnAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/61ac87aaa1095ccdd84968ed2b752235/e4706/homee_to_INSTAR_MQTT_16.avif 230w", "/en/static/61ac87aaa1095ccdd84968ed2b752235/d1af7/homee_to_INSTAR_MQTT_16.avif 460w", "/en/static/61ac87aaa1095ccdd84968ed2b752235/5239c/homee_to_INSTAR_MQTT_16.avif 808w"],
              "sizes": "(max-width: 808px) 100vw, 808px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/61ac87aaa1095ccdd84968ed2b752235/a0b58/homee_to_INSTAR_MQTT_16.webp 230w", "/en/static/61ac87aaa1095ccdd84968ed2b752235/bc10c/homee_to_INSTAR_MQTT_16.webp 460w", "/en/static/61ac87aaa1095ccdd84968ed2b752235/2b269/homee_to_INSTAR_MQTT_16.webp 808w"],
              "sizes": "(max-width: 808px) 100vw, 808px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/61ac87aaa1095ccdd84968ed2b752235/81c8e/homee_to_INSTAR_MQTT_16.png 230w", "/en/static/61ac87aaa1095ccdd84968ed2b752235/08a84/homee_to_INSTAR_MQTT_16.png 460w", "/en/static/61ac87aaa1095ccdd84968ed2b752235/3534c/homee_to_INSTAR_MQTT_16.png 808w"],
              "sizes": "(max-width: 808px) 100vw, 808px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/61ac87aaa1095ccdd84968ed2b752235/3534c/homee_to_INSTAR_MQTT_16.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`As you can see in the screenshot above we are using the MQTT Topics to switch the audio on our camera on or off. `}<strong parentName="p">{`Note`}</strong>{` that there are 3 audio topics that we have to switch for all 3 video streams of our camera - high resolution, medium resolution and low resolution:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/multimedia/audio/enable/high/raw`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/multimedia/audio/enable/mid/raw`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/multimedia/audio/enable/low/raw`}</code></li>
    </ul>
    <p>{`To receive the current state of the audio settings on our camera we have to attach MQTT input nodes with the corresponding `}<strong parentName="p">{`Status Topic`}</strong>{` to the input of our virtual homee:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/status/multimedia/audio/enable/high/raw`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/status/multimedia/audio/enable/mid/raw`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/status/multimedia/audio/enable/low/raw`}</code></li>
    </ul>
    <p>{`To be able to work with this input we first have to turn it into valid JSON, by adding a JSON node. And then sort out our data with a custom function node:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`msg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'id'`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`101`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'value'`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`Number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`msg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`val`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` msg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`So the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`value`}</code>{` that we receive from the MQTT Status input will be either {"val":"1"} or {"val":"0"}. We simply extract the value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`val`}</code>{` from this payload, turn it into a number and add the ID of our virtual homee device (our previous device had the ID `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`100`}</code>{`, so now we use the id `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`101`}</code>{` for our new device).`}</p>
    <p>{`The new device is again configured to be an on/off switch. We copy our previous homee node - only need to change the name `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Audio`}</code>{`, device ID `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`101`}</code>{` and NodeID `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2`}</code>{` to keep them unique:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "756px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f429bfe9a6157471991a0eae337c9c04/8ae3e/homee_to_INSTAR_MQTT_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABkElEQVQoz22RXW/aMBSG8/92v1+0i95V+x0tnbYKwpRChZjEWikh0Kk3aCVD6kBDYMc+/jq2k81BbMB4JMtXj9/3HEfj/yh+FC/z+Ww2K4pisVgwxvgBALC7OEB0YqZpSggRMsCOKcsSOBfGbF/mvTdvpxeXp3KWZev1erVaLZdLKZUxRimFe4wxBvHX68/Fl9Hr9FuUZdlJMgB47xGxbqiOqetaae2qCisfPT485nl+mEwpRbS+ARGdcye+977y4ZUzchiuZBw4Im42GymE997tsdZqrXe9juU0C8mENM0rZ61oRjhLI399+Oc+P6d5zhmTUmqtrQ3ldz3Py+N8Ms6n6XgymeTfL98/DQaEc84YInrvKaXAuTvGWuucC/IouRndfRgO7nr3/c/vLvofPzEAo01d1957KSUi/t3U4c6CPEziYdIe9pPbTuc26fX69yWlLCRbay0hRCm9/+PmIGqtjQmvR/FN0m5129fdbivuXnXiVgwAiEYIoZTabrdCCABgYf3ASiZAlCUVQv6RfwMrwYz7nEhL3AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f429bfe9a6157471991a0eae337c9c04/e4706/homee_to_INSTAR_MQTT_17.avif 230w", "/en/static/f429bfe9a6157471991a0eae337c9c04/d1af7/homee_to_INSTAR_MQTT_17.avif 460w", "/en/static/f429bfe9a6157471991a0eae337c9c04/b91b2/homee_to_INSTAR_MQTT_17.avif 756w"],
              "sizes": "(max-width: 756px) 100vw, 756px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f429bfe9a6157471991a0eae337c9c04/a0b58/homee_to_INSTAR_MQTT_17.webp 230w", "/en/static/f429bfe9a6157471991a0eae337c9c04/bc10c/homee_to_INSTAR_MQTT_17.webp 460w", "/en/static/f429bfe9a6157471991a0eae337c9c04/b5834/homee_to_INSTAR_MQTT_17.webp 756w"],
              "sizes": "(max-width: 756px) 100vw, 756px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f429bfe9a6157471991a0eae337c9c04/81c8e/homee_to_INSTAR_MQTT_17.png 230w", "/en/static/f429bfe9a6157471991a0eae337c9c04/08a84/homee_to_INSTAR_MQTT_17.png 460w", "/en/static/f429bfe9a6157471991a0eae337c9c04/8ae3e/homee_to_INSTAR_MQTT_17.png 756w"],
              "sizes": "(max-width: 756px) 100vw, 756px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f429bfe9a6157471991a0eae337c9c04/8ae3e/homee_to_INSTAR_MQTT_17.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in the homee UI you can now go back to the menu where we added our virtual homee before. Instead of adding a new homee we now have to search for new devices. I named the new device `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Audio`}</code>{` in the previous step:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "758px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/c8e86/homee_to_INSTAR_MQTT_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACcUlEQVQ4y23S3W6iQBiAYY+bKDCAVAaQYX5gQBlTumKsdtVGdtGu0aXqBexp76J74GHTK95IW3+6PiHDhORNhi9T4ZwzxgghrVYriiLGWBAEjLHb29tBaTgc9nq97olvpSRJKrZtO45druXLtiGEvu8zxrxP6Jznea7rxnFc0TStXq8bhqHruqZpuq4DAMIwbDQa1WoVAKBpmqqqh42maQCAq6srznklavMwCjwPY4wRcgkhzWYzjmPTNCVJMgwDQtgomab5vuq6Xq1WwzCszH5/z34NGQ2YzyiljDGEkBDCNE1FUeondF0/7Gu12j72kOchz3Ec122WXMuyhBAQQlmWwSWqqtZqtSiKKlmWPTw8YIwJoQST92MLIQghtuM0XRdalnHu+vpaVdX9wAaDQb/f/zJtIQRCCDYaNoSWaX459jEOfD/wfYwxpZQQUo4NtYVIeDj2g3EQDH3fKe1/yXUhhIZhfMTF449lPkWYtNvtPM8fP81ms3n5PM7ns9lssVhMJpMsyzjnkiR9THt/GxiFJuQhv7vrp2navSRN05ubm2636/v+MU7TXpqmlNJOp5OmaZIkotT5jxBiNBoxxo7xsIQQarVa/X7//v5+Op1+uczver3e29sbpfQYqyUAAELo+fl5t9v9veTl5WW3272+viKEZFn+iA83gVKaZdl8Pi+KYr1eLxaLn6U8z4uiWK1WeZ5PJhNK6TFWFAUAIMsyxvj7udFoNB6PT7+Mx+MLsSRJGOPlcrndbjebzXq9LoriT+np6WlT2m63q9WKMXYWK4oiy7JlWVEUhWHIOU+SJI7jIAg45+GJKIps2z7E/wBAhrhVttbmgAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/e4706/homee_to_INSTAR_MQTT_18.avif 230w", "/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/d1af7/homee_to_INSTAR_MQTT_18.avif 460w", "/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/9eff3/homee_to_INSTAR_MQTT_18.avif 758w"],
              "sizes": "(max-width: 758px) 100vw, 758px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/a0b58/homee_to_INSTAR_MQTT_18.webp 230w", "/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/bc10c/homee_to_INSTAR_MQTT_18.webp 460w", "/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/c4e8e/homee_to_INSTAR_MQTT_18.webp 758w"],
              "sizes": "(max-width: 758px) 100vw, 758px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/81c8e/homee_to_INSTAR_MQTT_18.png 230w", "/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/08a84/homee_to_INSTAR_MQTT_18.png 460w", "/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/c8e86/homee_to_INSTAR_MQTT_18.png 758w"],
              "sizes": "(max-width: 758px) 100vw, 758px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d26a4ddb6d581d07f7b8a1436e8c9e1c/c8e86/homee_to_INSTAR_MQTT_18.png",
              "alt": "Homee to INSTAR MQTT Broker",
              "title": "Homee to INSTAR MQTT Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now you can try it out - deactivating the audio in the camera webUI should now also toggle the switch in our homee device UI:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/392b303000a5269846147a4a2ee6e372/homee_to_INSTAR_MQTT_19.gif",
        "alt": "Homee to INSTAR MQTT Broker"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote">{`You are now able to use any of the available `}<a parentName="p" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/"
        }}>{`MQTT Topics`}</a>{` and take control over your camera through the homee UI. And every button, switch or slider you add will be available for automation scripts.`}</p>
    </blockquote>
    <h2 {...{
      "id": "node-red-flow-export",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-flow-export",
        "aria-label": "node red flow export permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Flow Export`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"75393df8.e86824"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"homeeDevice"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"virtual-homee"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Alarm_Areas"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"nodeId"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"profile"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"10"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"icon"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"nodeicon_camera"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"attributes"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"[{\\"id\\":100,\\"node_id\\":1,\\"instance\\":0,\\"minimum\\":0,\\"maximum\\":1,\\"current_value\\":0,\\"target_value\\":0,\\"last_value\\":0,\\"unit\\":\\"\\",\\"step_value\\":1,\\"editable\\":1,\\"type\\":1,\\"state\\":1,\\"last_changed\\":12345555,\\"changed_by\\":1,\\"changed_by_id\\":0,\\"based_on\\":1,\\"data\\":\\"\\"}]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`110`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`180`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9fe0bb5a.f639d8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"542704b9.3c440c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/area1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/local/alarm/area1/enable/raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`475`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`121`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"9fe0bb5a.f639d8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"change"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"targetValue"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"set"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"p"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload.targetValue"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"reg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`270`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`180`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"542704b9.3c440c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ef88a520.e5a8b8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b0746487.26ede8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"63750b59.349f54"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"63750b59.349f54"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/area2/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/local/alarm/area2/enable/raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`475`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`161`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ef88a520.e5a8b8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/area3/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/local/alarm/area3/enable/raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`475`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`201`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b0746487.26ede8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alarm/area4/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/local/alarm/area4/enable/raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`475`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`241`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"fd87a90.65ebe58"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"homeeDevice"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"virtual-homee"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Audio"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"nodeId"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"profile"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"10"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"icon"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"nodeicon_camera"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"attributes"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"[{\\"id\\":101,\\"node_id\\":2,\\"instance\\":0,\\"minimum\\":0,\\"maximum\\":1,\\"current_value\\":0,\\"target_value\\":0,\\"last_value\\":0,\\"unit\\":\\"\\",\\"step_value\\":1,\\"editable\\":1,\\"type\\":1,\\"state\\":1,\\"last_changed\\":12345555,\\"changed_by\\":1,\\"changed_by_id\\":0,\\"based_on\\":1,\\"data\\":\\"\\"}]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`716`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`320`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"e45a2b45.bb1778"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"494dca42.c53fd4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"multimedia/audio/enable/high"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/local/multimedia/audio/enable/high/raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`664`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`376`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"e45a2b45.bb1778"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"change"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"targetValue"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"set"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"p"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pt"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload.targetValue"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"tot"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"from"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"to"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"reg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`428`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`376`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"494dca42.c53fd4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"40c78b19.acda34"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"6342ae78.ee176"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"63e55ead.2a5af"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"status/multimedia/audio/enable/high"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/local/status/multimedia/audio/enable/high"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"datatype"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`160`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`320`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2b0f2fa0.c0d32"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"fc32aaac.edce58"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"function"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"attributeId/targetValue"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"func"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"msg.payload = {'id':101, 'value': Number(msg.payload.val)}\\n\\nreturn msg;"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"outputs"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"noerr"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`550`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`320`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"fd87a90.65ebe58"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2b0f2fa0.c0d32"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"property"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"payload"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"action"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"pretty"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`385`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`320`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"fc32aaac.edce58"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"40c78b19.acda34"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"multimedia/audio/enable/mid"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/local/multimedia/audio/enable/mid/raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`654`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`409`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"6342ae78.ee176"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt out"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"multimedia/audio/enable/low"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/local/multimedia/audio/enable/low/raw"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"retain"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`654`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`442`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"296264ec.9473bc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"status/multimedia/audio/enable/mid"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/local/status/multimedia/audio/enable/mid"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"datatype"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`160`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`353`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2b0f2fa0.c0d32"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1e5b6840.5de388"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt in"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"5026c83e.b368c8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"status/multimedia/audio/enable/low"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"topic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar/local/status/multimedia/audio/enable/low"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"qos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"datatype"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"auto"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"x"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`160`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"y"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`386`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"wires"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2b0f2fa0.c0d32"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"996228fe.ababc8"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt-broker"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"z"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"INSTAR MQTT"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"broker"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.117"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"port"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1883"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"clientid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"iored"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"usetls"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"compatmode"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"keepalive"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"60"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"cleansession"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"birthPayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closeQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"closePayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willTopic"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willQos"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"willPayload"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      